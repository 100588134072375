// Generated by Framer (38fb4ea)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Embed from "https://framerusercontent.com/modules/o1PI5S8YtkA5bP5g4dFz/9zLIz4fn80IR9zpOx18Q/Embed.js";
import * as localizedValues from "./F02fGRgTv-0.js";
const EmbedFonts = getFonts(Embed);

const cycleOrder = ["Pc67S9e8d"];

const serializationHash = "framer-hXl0N"

const variantClassNames = {Pc67S9e8d: "framer-v-mljkpa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {IGeMnN5IH: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Pc67S9e8d", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-mljkpa", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Pc67S9e8d"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-1x7tsrh-container"} layoutDependency={layoutDependency} layoutId={"aY8x5ZFmX-container"}><Embed height={"100%"} html={"<!-- TradingView Widget BEGIN -->\n<div class=\"tradingview-widget-container\">\n  <div class=\"tradingview-widget-container__widget\"></div>\n  <div class=\"tradingview-widget-copyright\"><a href=\"https://www.tradingview.com/\" rel=\"noopener nofollow\" target=\"_blank\"><span class=\"blue-text\">Track all markets on TradingView</span></a></div>\n  <script type=\"text/javascript\" src=\"https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js\" async>\n  {\n  \"symbols\": [\n    {\n      \"description\": \"USD CAD\",\n      \"proName\": \"FX:USDCAD\"\n    },\n    {\n      \"description\": \"EUR CAD\",\n      \"proName\": \"FX:EURCAD\"\n    },\n    {\n      \"description\": \"USD EUR\",\n      \"proName\": \"FX_IDC:USDEUR\"\n    }\n  ],\n  \"showSymbolLogo\": true,\n  \"isTransparent\": true,\n  \"displayMode\": \"regular\",\n  \"colorTheme\": \"light\",\n  \"locale\": \"en\"\n}\n  </script>\n</div>\n<!-- TradingView Widget END -->"} id={"aY8x5ZFmX"} layoutId={"aY8x5ZFmX"} style={{height: "100%", width: "100%"}} type={"html"} url={""} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hXl0N.framer-12f7yxi, .framer-hXl0N .framer-12f7yxi { display: block; }", ".framer-hXl0N.framer-mljkpa { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 46px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1212px; }", ".framer-hXl0N .framer-1x7tsrh-container { flex: none; height: 76px; position: relative; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hXl0N.framer-mljkpa { gap: 0px; } .framer-hXl0N.framer-mljkpa > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-hXl0N.framer-mljkpa > :first-child { margin-left: 0px; } .framer-hXl0N.framer-mljkpa > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 46
 * @framerIntrinsicWidth 1212
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerF02fGRgTv: React.ComponentType<Props> = withCSS(Component, css, "framer-hXl0N") as typeof Component;
export default FramerF02fGRgTv;

FramerF02fGRgTv.displayName = "Currency Ticker";

FramerF02fGRgTv.defaultProps = {height: 46, width: 1212};

addFonts(FramerF02fGRgTv, [{explicitInter: true, fonts: []}, ...EmbedFonts], {supportsExplicitInterCodegen: true})